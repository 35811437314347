import React from 'react';
import {get, checkHttpStatus} from "../../utils/RestUtilsWithAxios";
import {GET_PENDING_TRADE_LIST} from '../../constants/uri';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { injectIntl } from "react-intl";
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
/**
 * PendingTrade
 * @param props
 * @return {*}
 * @constructor
 */
function PendingTrade (props) {
    const [pendingTradeList, setPendingTradeList] = React.useState(null);
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('accNo');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(()=>{
        get(GET_PENDING_TRADE_LIST)
        .then(function (response) {
            setPendingTradeList(response.data);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    }, []);

    
    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const headClasses = headStyles();
    return (
        <>
        { pendingTradeList != null  ?  
        <div>
            <Paper className={classes.paper}>
            <div className={classes.root}>
            
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={pendingTradeList.length}
                        intl={props.intl}
                    />
                    <TableBody>
                    {stableSort(pendingTradeList, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.name + index}
                                >
                                    <TableCell component="th" key={"vlEndDate"+index} id={"vlEndDate"+index}
                                                className={classes.fontSize13}> {row.vlEndDate} </TableCell>
                                    <TableCell component="th" key={"title"+index} id={"title"+index}
                                                className={classes.fontSize13}> {row.title} </TableCell>
                                    <TableCell component="th" key={"mbrAccNo"+index} id={"mbrAccNo"+index}
                                                className={classes.fontSize13}> {row.mbrAccNo} </TableCell>
                                    <TableCell component="th" key={"exchCode"+index} id={"exchCode"+index}
                                                className={classes.fontSize13}> {row.exchCode} </TableCell>
                                    <TableCell component="th" key={"isinAndDesc"+index} id={"isinAndDesc"+index}
                                                className={classes.fontSize13}> {row.isinAndDesc} </TableCell>
                                    <TableCell align="right"  key={"amount"+index}
                                                id={"amount"+index} className={classes.fontSize13}>
                                        <NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                        value={row.amount} decimalSeparator=','
                                                        thousandSeparator='.' displayType='text' />
                                    </TableCell>
                                    <TableCell component="th" key={"instType"+index} id={"instType"+index}
                                                className={classes.fontSize13}> {row.instType} </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                 </Table>
                 <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={pendingTradeList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonText = {props.intl.formatMessage({id:"TABLE.PAGINATION.PREVIOUS"})}
                nextIconButtonText = {props.intl.formatMessage({id:"TABLE.PAGINATION.NEXT"})}
                labelRowsPerPage = {props.intl.formatMessage({id:"TABLE.PAGINATION.PER_PAGE"})}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            </div>
            </Paper>
        </div>
        : 
        null}
        <Paper className={classes.infoMsg}>
        <Grid container style={{backgroundColor: "white"}}>
            <InfoIcon size="small" style={{marginRight:8}} />
            <Typography display="inline" variant="subtitle1">
                Bu sayfada yer alan veriler, Borsa İstanbul’da gerçekleşen işlemlerin Takasbank tarafından yatırımcı bazında netleştirilerek işlem günü akşamı aktarılmasıyla oluşmakta olup, henüz gerçekleşmemiş talimatları içermektedir. 
                Talimatın bu sayfada yer alıyor olması, işlemin gerçekleşeceği garantisini vermemektedir. Detaylar için işleminizi yaptığınız yatırım kuruluşlarından bilgi alabilirsiniz.
            </Typography>
        </Grid>
        </Paper>
        </>
)
}

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;

}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    if(array != null){
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }   
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        ['@media (max-width:1024px)']: {
            fontSize: 11
        },
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'vlEndDate',     numeric: false, disablePadding: false, label:  props.intl.formatMessage({id:"PENDING_TRADE.VL_END_DATE"}) },
        { id: 'title',   numeric: false, disablePadding: false, label: props.intl.formatMessage({id:"PENDING_TRADE.TITLE"}) },
        { id: 'mbrAccNo',  numeric: false, disablePadding: false, label: props.intl.formatMessage({id:"PENDING_TRADE.MBR_ACC_NO"}) },
        { id: 'exchCode',  numeric: false, disablePadding: false, label: props.intl.formatMessage({id:"PENDING_TRADE.EXCH_CODE"}) },
        { id: 'isinAndDesc',  numeric: false, disablePadding: false, label: props.intl.formatMessage({id:"PENDING_TRADE.ISIN_AND_DESC"}) },
        { id: 'amount',  numeric: true, disablePadding: false, label: props.intl.formatMessage({id:"PENDING_TRADE.AMOUNT"}) },
        { id: 'instType',  numeric: false, disablePadding: false, label: props.intl.formatMessage({id:"PENDING_TRADE.INST_TYPE"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
    },
    showButton: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    textContainer: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30
    },
    unlimitedText: {
        [theme.breakpoints.down('xs')]: {
            fontSize:16
        },
        color: "white",
    },table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13,
        ['@media (max-width:1024px)']: {
            fontSize: 11
        },
    }
    
}));

export default injectIntl((PendingTrade));