import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { alertBox } from "../../../components/BoxProvider";
import * as Regex from "../../../constants/Regexp";
import {
  post,
  checkHttpStatus,
  get,
  enableSpinner,
  disableSpinner,
} from "../../../utils/RestUtilsWithAxios";
import {
  UPDATE_PASSWORD,
  GET_CHANGE_PASSWORD_CODE,
} from "../../../constants/uri";
import * as Globals from "../../../constants/globals";
import Divider from "@material-ui/core/Divider";
import CountDownContainer from "../../../utils/CountDownContainer";
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    width: "100%",
    maxWidth: 450,
  },
  showButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: "#aa332340",
  },
  boldText: {
    fontWeight: "bold",
  },
}));

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function ChangePassword(props) {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [codeDialog, setCodeDialog] = useState(false);
  const [currentCodeDialog, setCurrentCodeDialog] = useState(false);
  const [countDown, setCountDown] = useState(false);
  const [confirmCodeBlock, setConfirmCodeBlock] = useState(false);

  const { intl } = props;
  /**
   *charCodeControl
   * @param event
   */
  const charCodeControl = (event) => {
    var charCode = event.charCode
      ? event.charCode
      : event.keyCode
      ? event.keyCode
      : event.which
      ? event.which
      : 0;
    var valid = charCode >= 48 && charCode <= 57;
    if (!valid) {
      event.preventDefault();
    }
  };

  const removeTimer = () => {
    setCountDown(false);
  };

  function confirmApproveCode() {
    var postData = {};
    postData["password"] = document.getElementById("newPassword").value;
    postData["verifyPassword"] = document.getElementById(
      "repeatPassword"
    ).value;
    postData["fromExpired"] = false;
    postData["confirmVal"] =  document.getElementById("confirmCode") == undefined ? null : document.getElementById("confirmCode").value;
    postData["currentPassword"] = document.getElementById("currPassword") == undefined ? null :  document.getElementById("currPassword").value;

 
    post(UPDATE_PASSWORD, postData)
      .then(function(response) {
        if (response.data.status) {
          setCodeDialog(false);
          setCurrentCodeDialog(false)
          alertBox(
            props.intl.formatMessage({
              id: "SECURITY.ALERT.SUCCESS_TITLE",
            }),
            props.intl.formatMessage({
              id: "SECURITY.ALERT.PASSOWRD_CHANGED",
            }),
            Globals.ALERT_SUCCESS,
            props.intl
          );
        } else
          alertBox(
            props.intl.formatMessage({
              id: "SECURITY.ALERT.ERROR_TITLE",
            }),
            response.data.exception.message,
            Globals.ALERT_ERROR,
            props.intl
          );
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  const timeIsUp = () => {
    setCountDown(false);
    if (document.getElementById("infoArea3") === null) {
    } else {
      document.getElementById("infoArea3").innerHTML =
        "Süreniz dolmuştur.Yeni kod almak için " +
        "Onay Kodu Gönder butonunu tıklayınız:";
    }
  };

  const shouldSendCodeToSms = () => {
    var postData = {};
    postData["password"] = document.getElementById("newPassword").value;
    postData["verifyPassword"] = document.getElementById(
      "repeatPassword"
    ).value;
    postData["fromExpired"] = false;

    post(GET_CHANGE_PASSWORD_CODE, postData)
      .then(function(response) {
        if (response.data.status === "OK") {
          setCodeDialog(true);
          setCountDown(true);
          setConfirmCodeBlock(false);

          document.getElementById("pleasecode").innerHTML =
            response.data.gsmNo +
            " numaralı telefonunuza gönderilen SMS onay kodunu giriniz.";

          document.getElementById("infoArea3").innerHTML =
            "* Gönderilen onay kodu mesajlarının geçerlilik süresi 3 dakikadır. " +
            "Tekrar onay kodu talep etmeniz için gereken süreniz:";
        } else if (response.data.gsmNo === null) {
          setCurrentCodeDialog(true);
        } else {
          this.setState({ sendCode: false });
        }
      })
      .catch(function(error) {
        disableSpinner();
        checkHttpStatus(error, props.intl);
      });
  };

  return (
    <Grid container style={{ maxWidth: 450 }} direction="column">
      <h3>
        {props.intl.formatMessage({ id: "SECURITY.FORM.PASSWORD_TITLE" })}
      </h3>
      <Divider className={classes.divider} />
      <Formik
        enableReinitialize
        initialValues={{
          newPassword: password,
          repeatPassword: repeatPassword,
        }}
        validate={(values) => {
          const errors = {};

          setPassword(values.newPassword);
          setRepeatPassword(values.repeatPassword);

          if (!Regex.PASSWORD.test(values.newPassword)) {
            errors.newPassword = props.intl.formatMessage({
              id: "AUTH.VALIDATION.SIX_DIGITS",
            });
          }

          if (!Regex.PASSWORD.test(values.repeatPassword)) {
            errors.repeatPassword = props.intl.formatMessage({
              id: "AUTH.VALIDATION.SIX_DIGITS",
            });
          }

          if (!Regex.PASSWORD.test(values.currPassword)) {
            errors.currPassword = props.intl.formatMessage({
              id: "AUTH.VALIDATION.SIX_DIGITS",
            });
          }

          return errors;
        }}>
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {status && (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{status}</div>
              </div>
            )}

            <div className="form-group mb-0" style={{ height: 94 }}>
              <TextField
                id="newPassword"
                margin="normal"
                label={props.intl.formatMessage({
                  id: "SECURITY.FORM.NEW_PASSWORD",
                })}
                className="kt-width-full"
                name="newPassword"
                disabled={false}
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassWord}
                helperText={touched.newPassword && errors.newPassword}
                error={Boolean(touched.newPassword && errors.newPassword)}
                onKeyPress={(event) => {
                  charCodeControl(event);
                }}
                inputProps={{
                  maxLength: 6,
                  type: "password",
                }}
                InputProps={{
                  endAdornment: (
                    <a onClick={() => setDialogOpen(true)}>
                      <InfoIcon color="primary" size="large" />
                    </a>
                  ),
                }}
              />
            </div>

            <div className="form-group mb-0" style={{ height: 94 }}>
              <TextField
                id="repeatPassword"
                label={props.intl.formatMessage({
                  id: "SECURITY.FORM.REPEAT_PASSWORD",
                })}
                margin="normal"
                className="kt-width-full"
                name="repeatPassword"
                disabled={false}
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.repeatPassword}
                helperText={touched.repeatPassword && errors.repeatPassword}
                error={Boolean(touched.repeatPassword && errors.repeatPassword)}
                onKeyPress={(event) => {
                  charCodeControl(event);
                }}
                inputProps={{
                  maxLength: 6,
                  type: "password",
                }}
              />
            </div>

            <div>
              <Dialog
                open={codeDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                style={{ textAlign: "left" }}
              >
                <DialogTitle id="form-dialog-title">
                  {props.intl.formatMessage({
                    id: "AUTH.VALIDATION.ENTER_WITH_APPROVE_CODE",
                  })}
                </DialogTitle>
                <DialogContent>
                  <Typography gutterBottom variant="body1">
                    {"Sayın " + localStorage.getItem("NameSurname")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    id="pleasecode"
                  ></Typography>

                  <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="confirmCode"
                    label="Onay Kodu"
                    type="numeric"
                    variant="outlined"
                  />
                  <div>
                    <DialogContentText style={{ marginBottom: 0 }}>
                      <br />
                      <Typography
                        variant="subtitle1"
                        id="infoArea3"
                      ></Typography>
                    </DialogContentText>
                    {/* <span className="form-text" id="infoArea3">
                          </span> */}
                    {countDown ? (
                      <CountDownContainer minutes={3} endFunction={timeIsUp} />
                    ) : null}
                  </div>
                  <div>
                    <DialogContentText style={{ marginBottom: 0 }}>
                      <br />
                      <Typography
                        variant="subtitle1"
                        id="infoArea3"
                      ></Typography>
                    </DialogContentText>
                    {/* <span className="form-text" id="infoArea3">
                          </span> */}
                    {}
                  </div>
                </DialogContent>
                <DialogActions style={{ padding: 16 }}>
                  <Button
                    color="primary"
                    disabled={countDown && !confirmCodeBlock}
                    onClick={() => {
                      removeTimer();
                      shouldSendCodeToSms();
                    }}
                  >
                    {props.intl.formatMessage({
                      id: "AUTH.VALIDATION.SEND_APPROVE_CODE",
                    })}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setCodeDialog(false);
                    }}
                  >
                    {props.intl.formatMessage({ id: "COMMON.BUTTON_CANCEL" })}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    id="approveButton"
                    onClick={() => {
                      confirmApproveCode();
                    }}
                  >
                    {props.intl.formatMessage({ id: "COMMON.BUTTON_ENTER" })}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div>
              <Dialog
                open={currentCodeDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                style={{ textAlign: "left" }}
              >
                <DialogTitle id="form-dialog-title">
                  {props.intl.formatMessage({
                    id: "SECURITY.FORM.PASSWORD_TITLE",
                  })}
                </DialogTitle>
                <DialogContent>

                <Typography gutterBottom variant="body1">
                    {"Kayıtlı telefon numaranız bulunamamıştır. Eski şifrenizi girerek şifre güncelleme işleminizi tamamlayabilirsiniz."}
                  </Typography>

                  <Typography gutterBottom variant="body1">
                    {"Sayın " + localStorage.getItem("NameSurname")}
                  </Typography>

                  <div className="form-group mb-0" style={{ height: 94 }}>
                    <TextField
                      id="currPassword"
                      margin="normal"
                      label={props.intl.formatMessage({
                        id: "SECURITY.FORM.CURR_PASSWORD",
                      })}
                      className="kt-width-full"
                      name="currPassword"
                      disabled={false}
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.newPassWord}
                      helperText={touched.currPassword && errors.currPassword}
                      error={Boolean(
                        touched.currPassword && errors.currPassword
                      )}
                      onKeyPress={(event) => {
                        charCodeControl(event);
                      }}
                      inputProps={{
                        maxLength: 6,
                        type: "password",
                      }}
                    />
                  </div>
                </DialogContent>
                <DialogActions style={{ padding: 16 }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setCurrentCodeDialog(false);
                    }}
                  >
                    {props.intl.formatMessage({ id: "COMMON.BUTTON_CANCEL" })}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    id="approveButton"
                    onClick={() => {
                      confirmApproveCode();
                    }}
                  >
                    {props.intl.formatMessage({
                      id: "SECURITY.BUTTON.NEW_PASSWORD",
                    })}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <Grid container justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => {
                  shouldSendCodeToSms();
                }}
                className={classes.showButton}
              >
                {props.intl.formatMessage({ id: "COMMON.BUTTON_CONTINUE" })}
              </Button>
            </Grid>

            <Dialog
              open={dialogOpen}
              aria-labelledby="max-width-dialog-title"
              maxWidth={"xs"}
              fullWidth={true}
              style={{ textAlign: "left" }}
            >
              <DialogTitle id="max-width-dialog-title">
                {props.intl.formatMessage({
                  id: "SECURITY.MODAL.PASSWORD_TITLE",
                })}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <p>
                    <span className={classes.boldText}>1. </span>
                    {props.intl.formatMessage({
                      id: "SECURITY.MODAL.PASSWORD_DESC_1",
                    })}
                  </p>
                  <p>
                    <span className={classes.boldText}>2. </span>
                    {props.intl.formatMessage({
                      id: "SECURITY.MODAL.PASSWORD_DESC_2",
                    })}
                  </p>
                  <p>
                    <span className={classes.boldText}>3. </span>
                    {props.intl.formatMessage({
                      id: "SECURITY.MODAL.PASSWORD_DESC_3",
                    })}
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ padding: 16 }}>
                <Button onClick={() => setDialogOpen(false)} color="primary">
                  {props.intl.formatMessage({ id: "COMMON.MODAL_CLOSE" })}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </Grid>
  );
}
export default injectIntl(connect(null)(ChangePassword));
