import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {checkHttpStatus, get} from "../../utils/RestUtilsWithAxios";
import { GET_IHR_MEMBERS, GET_DI_IHR_MEMBERS} from "../../constants/uri";
import * as Globals from "../../constants/globals";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import GeneralGkReportPage from './GeneralGkReportPage';
import TemettuReportPage from './TemettuReportPage';
import SermayeArtirimReportPage from './SermayeArtirimReportPage';
import SermayeAzaltimReportPage from './SermayeAzaltimReportPage';
import DebtInstrumentsReportPage from './DebtInstrumentsReportPage';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    navButton: {
        maxWidth:'100%',
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Globals.COLOR_TRANSPARENT_RED,
        borderRadius: 4,   
      },
      activeNavButton: {
          maxWidth:'100%',
          marginLeft: 5,
          marginRight: 5,
          backgroundColor: Globals.COLOR_FULL_RED,
          borderRadius: 4,  
        },
      nav: {
          backgroundColor: "white",
          marginBottom:16,
          justifyContent: "space-around",
          height: "unset",
          padding: 5,
          borderRadius: 4,
          paddingLeft: 0,
          paddingRight: 0
      },
      navText: {
          color: "white",
          fontSize: 14
      }, 
}));

/**
 * GeneralHkPage
 * @param props
 * @return {*}
 * @constructor
 */
function GeneralHkPage(props) {
    React.useEffect(()=>{
        get(GET_IHR_MEMBERS)
            .then(function (response) {
                var setList = [];
                setList.push({value:'',label:'Seçim Yapınız'});
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setIhrMembers(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    },[]);

    React.useEffect(()=>{
        get(GET_DI_IHR_MEMBERS)
            .then(function (response) {
                var setList = [];
                setList.push({value:'',label:'Seçim Yapınız'});
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setDiIhrMembers(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    },[]);

    const classes = useStyles();
    const [pageValue, setPageValue] = React.useState(0);
    const [ihrMembers,setIhrMembers] = React.useState([]);
    const [diIhrMembers,setDiIhrMembers] = React.useState([]);

    return(
        <div className={classes.root}>
            <BottomNavigation
                value={pageValue}
                onChange={(event, newValue) => {
                    setPageValue(newValue);
                }}
                showLabels
                classes={{root: classes.nav}}
            >
                <BottomNavigationAction label={<span className={classes.navText}>Genel Kurul</span>}
                                        classes={{root: pageValue === 0 ?
                                                classes.activeNavButton :
                                                classes.navButton}}  />
                <BottomNavigationAction label={<span className={classes.navText}>Temettü</span>}
                                        classes={{root: pageValue === 1 ?
                                                classes.activeNavButton :
                                                classes.navButton}} />
                <BottomNavigationAction label={<span className={classes.navText}>Sermaye Artırım</span>}
                                        classes={{root: pageValue === 2 ?
                                                classes.activeNavButton :
                                                classes.navButton}} />
                <BottomNavigationAction label={<span className={classes.navText}>Sermaye Azaltım</span>}
                                        classes={{root: pageValue === 3 ?
                                                classes.activeNavButton :
                                                classes.navButton}} />
                <BottomNavigationAction label={<span className={classes.navText}>Borçlanma Araçları</span>}
                                        classes={{root: pageValue === 4 ?
                                                classes.activeNavButton :
                                                classes.navButton}} />
            </BottomNavigation>
            {
                pageValue === 0 ?
                    <GeneralGkReportPage/>
                :pageValue === 1 ?
                    <TemettuReportPage ihrMembers={ihrMembers}/>
                :pageValue === 2 ?
                    <SermayeArtirimReportPage ihrMembers={ihrMembers}/>
                :pageValue === 3 ?
                    <SermayeAzaltimReportPage ihrMembers={ihrMembers}/>
                :
                    <DebtInstrumentsReportPage ihrMembers={diIhrMembers}/>
            }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(GeneralHkPage)
);