import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { GET_ANNOUNCEMENT } from "../../../constants/uri";
import { checkHttpStatus, get, post } from "../../../utils/RestUtilsWithAxios";
import * as Globals from "../../../constants/globals";
import { FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "200px",
    margin: "0",
  },

  tableWrapper: {
    overflowX: "auto",
    backgroundColor: "transparent",
  },
  fontSize13: {
    fontSize: 13,
  },
  showButton: {
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },
  },
  whiteText: {
    color: "white",
  },
}));

 

 

/**
 * AnnouncementSubscription
 * @param props
 * @return {*}
 * @constructor
 */
function AnnouncementSubscription(props) {
  const { intl,announcementSubscriptionRows,setAnnouncementSubscriptionRows} = props;
  const classes = useStyles();
  const [update, setUpdate] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
 
 

  React.useEffect(() => {
    get(GET_ANNOUNCEMENT)
      .then(function(response) {
        setAnnouncementSubscriptionRows(response.data);
        setUpdate(false);
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }, [update]);

  /**
   * handleChange
   * @param event
   * @param annoTypeId
   * @param inputType
   */
  function handleChange(event, annoTypeId, inputType) {
    let data = announcementSubscriptionRows;
    data.forEach(function(row) {
      if (row.annoTypeId === annoTypeId) {
        const target = event.target;
        const value =
          target.type === "checkbox" ? target.checked : target.value;
        if (inputType === Globals.MOBILE) {
          row.viaSms = value;
          document.getElementById("viaSms_" + annoTypeId).value = value;
        } else {
          row.viaEmail = value;
          document.getElementById("viaEmail_" + annoTypeId).value = value;
        }
      }
    });
    setAnnouncementSubscriptionRows(data);
  }

 

  return (
    <div className={classes.root}>
           <Typography variant="body1" style={{ color: "red" }}>
           Bildirim Kanalı
              </Typography>
          <Table>  
            <TableBody>
              {announcementSubscriptionRows.map((row, index) => {
                
                return (
                  <TableRow key={row.name + index}> 
                    <TableCell
                      align="left"
                      style={{
                        border: "none",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      className={classes.fontSize13}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={"viaEmail_" + row.annoTypeId}
                            onChange={(event) =>
                              handleChange(event, row.annoTypeId, Globals.EMAIL)
                            }
                            defaultChecked={row.viaEmail}
                            inputProps={{ "aria-labelledby": row.annoTypeId }}
                            color="secondary"
                          />
                        }
                        label=" e-POSTA"
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        border: "none",
                        paddingLeft: 0,
                        marginBottom: 0,
                      }}
                      className={classes.fontSize13}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={"viaSms_" + row.annoTypeId}
                            onChange={(event) =>
                              handleChange(
                                event,
                                row.annoTypeId,
                                Globals.MOBILE
                              )
                            }
                            defaultChecked={row.viaSms}
                            inputProps={{ "aria-labelledby": row.annoTypeId }}
                            color="secondary"
                          />
                        }
                        label="SMS"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
      
     
    

      <Dialog
        open={dialogOpen}
        aria-labelledby="max-width-dialog-title"
        maxWidth={"sm"}
        fullWidth={true}
        style={{ textAlign: "left" }}
      >
        <DialogTitle id="max-width-dialog-title">
          {props.intl.formatMessage({
            id: "NOTIFICATION_PREFERENCE.MODAL.ANNOUNCEMENT_SUBSCRITION_TITLE",
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              {props.intl.formatMessage({
                id:
                  "NOTIFICATION_PREFERENCE.MODAL.ANNOUNCEMENT_SUBSCRITION_CONTENT",
              })}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button
            variant="contained"
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            {props.intl.formatMessage({ id: "COMMON.MODAL_CLOSE" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/**
 * injectIntl
 */
export default injectIntl(AnnouncementSubscription);
