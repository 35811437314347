import React from 'react';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import {fetchDownload} from '../../utils/RestUtils';
import {SAVE_KAP_DECLARATION, GET_KAP_ATTACHMENT} from "../../constants/uri";
import * as Globals from "../../constants/globals";
import { Formik } from "formik";
import Paper from "@material-ui/core/es/Paper/Paper";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/PostAddOutlined';
import {alertBox} from "../../components/BoxProvider";
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
import Dropdown from '../../components/Dropdown';
import Button from "@material-ui/core/Button";
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import NumberFormat from 'react-number-format';

var bigdecimal = require("bigdecimal");

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    overflowX: {
        overflowX: 'auto',
    },
    tableWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    titleGutter: {
        marginBottom: theme.spacing(1)
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    showButton: {
        [theme.breakpoints.up('xs')]: {
            width: 200
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
    },
    glowOnHover:{
        '&:hover': { color: '#5d78ff'},
    },
    fontBold: {
        fontWeight: 600
    },
    fontLight: {
        fontWeight: 'lighter'
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        position: 'absolute',
        marginLeft: 11,
        marginTop: -6,
        fontSize: 12,
        fontWeight: 'bold'
    },
    thinBox: {
        padding: 16,
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'solid'
    },
    typography: {
        fontSize: 12,
    }
}));

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 12,
        fontWeight: 600
    },
}));

function EnhancedTableHead(props) {
    const headClasses = headStyles();

    const headRows = [
        { id: 'col1', numeric: false, disablePadding: false, label: 'İşlem Tarihi' },
        { id: 'col2', numeric: false, disablePadding: false, label: 'İşlem Niteliği' },
        { id: 'col3', numeric: false, disablePadding: false, label: 'Sermaye Piyasası Aracının ISIN Kodu' },
        { id: 'col4', numeric: true, disablePadding: false, label: 'İşleme Konu Sermaye Piyasası Aracının Nominal Tutarı (TL)' },
        { id: 'col5', numeric: true, disablePadding: false, label: 'İşleme Konu Sermaye Piyasası Aracı Adedi' },
        { id: 'col6', numeric: true, disablePadding: false, label: 'İşlem Fiyatı (TL)' },
        { id: 'col7', numeric: true, disablePadding: false, label: 'İşlem Tutarı (TL)' },
        { id: 'col8', numeric: true, disablePadding: false, label: 'İşlem Öncesi Sahip Olunan Nominal Tutar (TL)' },
        { id: 'col9', numeric: true, disablePadding: false, label: 'İşlem Sonrası Sahip Olunan Nominal Tutar (TL)' },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        className={headClasses.headFont}
                    >
                        {
                            <div>
                                {row.label}
                            </div>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        onValueChange={(values) => {
            onChange({
                target: {
                  name: props.name,
                  value: (values.value === "." || values.value === ",") ? '0,' : values.value,
                },
            });
          }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={7}
        isNumericString
        allowNegative={false}
      />
    );
}

function PayDisi(props) {

    React.useEffect(()=>{
        setNameOrTitle(localStorage.getItem("NameSurname"));
    },[]);

    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    function beginInit(){
        var date = new Date();
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+(formatMonthOrDay(date.getDate())).toString();
    }

    function corrList() {
        var arr= [];
        var yes = {value:'Y',label:'EVET'};
        var no = {value:'N',label:'HAYIR'};
        arr.push(yes);
        arr.push(no);
        return arr;
    }

    function alimSatimArr() {
        var arr= [];
        var alim = {value:'Alım',label:'Alım'};
        var satim = {value:'Satım',label:'Satım'};
        arr.push(alim);
        arr.push(satim);
        return arr;
    }

    const charCodeControl = (event) => {
        var charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));
        var valid = (charCode >= 48 && charCode <= 57);
        if (!valid) {
            event.preventDefault();
        }
    };

    function openModal1(forAdd,i) {
        if(forAdd){
            setC1(null);
            tableValues.column2 = ''
            tableValues.column3 = ''
            tableValues.column4 = ''
            tableValues.column5 = ''
            tableValues.column6 = ''
            tableValues.column8 = ''
            tableValues.column9 = ''
        }else{
            setC1(tableItems[i].islemTarihi);
            tableValues.column2 = tableItems[i].islemNiteligi
            tableValues.column3 = tableItems[i].isinCode
            tableValues.column4 = tableItems[i].nominalTutar
            tableValues.column5 = tableItems[i].sermayePiyasasiAdedi
            tableValues.column6 = tableItems[i].islemFiyati
            tableValues.column8 = tableItems[i].islemdenOnceNominalTutar
            tableValues.column9 = tableItems[i].islemdenSonraNominalTutar
        }
        setOpenTableForAdd(forAdd);
        setSelectedRow(i);
        setOpenForTable(true);
    }

    function openModal2() {
        setOpenForPreview(true);
        var time;
        setInterval(function(){
            time = new Date();
            setCurrentTime(time.getDate().toString().padStart(2, '0') + '/' + (time.getMonth()+1).toString().padStart(2, '0') + '/' + time.getFullYear().toString() + ' ' + time.getHours().toString().padStart(2,'0') + ':' + time.getMinutes().toString().padStart(2,'0') + ':' +  time.getSeconds().toString().padStart(2,'0') );
        }, 1000)
    }

    function handleClose1(isOk,forAdd,i) {
        if(isOk){
            var newItem = { islemTarihi:c1,
                            islemNiteligi:tableValues.column2,
                            isinCode:tableValues.column3,
                            nominalTutar:parseFloat(tableValues.column4),
                            sermayePiyasasiAdedi:parseFloat(tableValues.column5),
                            islemFiyati:parseFloat(tableValues.column6),
                            islemTutari:parseFloat(calculateColumn7()),
                            islemdenOnceNominalTutar:parseFloat(tableValues.column8),
                            islemdenSonraNominalTutar:parseFloat(tableValues.column9)
                        };
            if(forAdd){
                tableItems.push(newItem);
            }else{
                tableItems[i] = newItem;
            }
            handleToplamlar(tableItems);
        }
        closeForTable();
    }

    function removeItem(i) {
        //splice metodu kullaninca ekrana yansimiyor
        var newTable = [];
        for(var j=0;j<tableItems.length;j++){
            if(i!==j)
                newTable.push(tableItems[j]);
        }
        setTableItems(newTable);
        handleToplamlar(tableItems);
    }

    function handleToplamlar(rows){
        var ta1 = new bigdecimal.BigDecimal("0");
        var ta2 = new bigdecimal.BigDecimal("0");
        var ts1 = new bigdecimal.BigDecimal("0");
        var ts2 = new bigdecimal.BigDecimal("0");

        for(var i=0; i<rows.length; i++){
            if(rows[i].islemNiteligi === 'Alım'){
                var addThis = new bigdecimal.BigDecimal(rows[i].nominalTutar.toString())
                ta1 = ta1.add(addThis)
                var addThis2 = new bigdecimal.BigDecimal(rows[i].islemTutari.toString())
                ta2 = ta2.add(addThis2)
            }
            else if(rows[i].islemNiteligi === 'Satım'){
                var addThis = new bigdecimal.BigDecimal(rows[i].nominalTutar.toString())
                ts1 = ts1.add(addThis)
                var addThis2 = new bigdecimal.BigDecimal(rows[i].islemTutari.toString())
                ts2 = ts2.add(addThis2)
            }
        }
        setToplamAlis1(formatDecimal(ta1.toString()));
        setToplamAlis2(formatDecimal(ta2.toString()));
        setToplamSatis1(formatDecimal(ts1.toString()));
        setToplamSatis2(formatDecimal(ts2.toString()));
    }

    function closeForTable() {
        setOpenForTable(false);
    }

    function handleClose2(isOk) {
        if(isOk){
            if(checked)
                sendDisclosure();
            else
                alertBox("", props.intl.formatMessage({id:"KAP_DISCLOSURE.ALERTBOX.FILL_CHECKBOX_MESSAGE"}), Globals.ALERT_WARNING, props.intl);
        }else {
            closeForPreview();
        }
    }

    function handleClose3() {
            downloadFile(successDeclarationId,'Bildirim_'+successDeclarationId+'.pdf');
            closeForDocument();

    }

    function closeForPreview() {
        setOpenForPreview(false);
    }

    function closeForDocument() {
        setOpenForDocument(false);
    }

    function formatDate(date) {
        var formattedDate = date.substring(6) + "/" + date.substring(4,6) + "/" + date.substring(0,4);
        return formattedDate; 
    }

    function formatDecimal(num) {
        if(num == null || num === 'NaN')
            return '';
        var formattedNum = '';
        if(num.includes('.'))
            formattedNum = num.split('.')[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',' + num.split('.')[1];
        else
            formattedNum = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        return formattedNum
    }

    function formatTableItems(table) {
        var newTable= []
        if(table && table.length > 0){
            for(var i=0; i<table.length; i++){
                var newItem = { islemTarihi:formatDate(table[i].islemTarihi),
                    islemNiteligi:table[i].islemNiteligi,
                    isinCode:table[i].isinCode,
                    nominalTutar:formatDecimal(table[i].nominalTutar.toString()),
                    sermayePiyasasiAdedi:formatDecimal(table[i].sermayePiyasasiAdedi.toString()),
                    islemFiyati:formatDecimal(table[i].islemFiyati.toString()),
                    islemTutari:formatDecimal(table[i].islemTutari.toString()),
                    islemdenOnceNominalTutar:formatDecimal(table[i].islemdenOnceNominalTutar.toString()),
                    islemdenSonraNominalTutar:formatDecimal(table[i].islemdenSonraNominalTutar.toString())
                };
                newTable.push(newItem)
            }
        }
        return newTable;
    }

    function downloadFile(fileOid, fileName) {
        fetchDownload(GET_KAP_ATTACHMENT+fileOid,fileName);

    }

    const clearForm = () => {
        values.eyatUserId= props.myselfId;
        values.subject= '';
        values.company= '';
        values.correction= correctionList[1].value;
        values.nameAndSurname= nameOrTitle;
        values.tuzelName= '';
        values.gorev= '';
        values.otherNames= '';
        values.declaration= '........... tarihinde ........ TL fiyattan toplam ................ adet özel sektör tahvili/banka bonosu/...... alış/satış işlemi tarafımızca gerçekleştirilmiştir. İşlemin detaylarına ilişkin bilgiler aşağıdaki tabloda verilmektedir.';
        setTableItems([]);

        document.getElementById('subject').focus();
        document.getElementById('subject').blur();

        setOpenForClear(false);
        alertBox("", props.intl.formatMessage({id:"KAP_DISCLOSURE.ALERTBOX.FORM_CLEARED"}), Globals.ALERT_SUCCESS, props.intl);
    }


    function sendDisclosure() {
        enableLoading();
        var postData={
            templateNo:2,
            member:companyTitle,
            memberId:values.company,
            isCorrection:values.correction,
            corporateRep:values.tuzelName,
            otherActors:values.otherNames,
            declerationText:values.declaration,
            position:values.gorev,
            subject:values.subject,
            principalEyatUserId:values.eyatUserId,
            isAttorney: values.eyatUserId !== props.myselfId,
            toplamAlis1:toplamAlis1,
            toplamAlis2:toplamAlis2,
            toplamSatis1:toplamSatis1,
            toplamSatis2:toplamSatis2,
            tableItems02:formatTableItems(tableItems)
        };
        post(SAVE_KAP_DECLARATION,postData)
            .then(function (response) {
                setSuccessDeclarationId(response.data);
                closeForPreview();
                setOpenForDocument(true);
                disableLoading();
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl,true);
                disableLoading();
            })
        setChecked(false);
    }

    const classes = useStyles();
    const theme = useTheme();
    const [currentTime, setCurrentTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [companyTitle,setCompanyTitle] = useState('');
    const alimSatimList = alimSatimArr();
    const correctionList = corrList();
    const [nameOrTitle,setNameOrTitle] = useState('');
    const [toplamAlis1,setToplamAlis1] = useState('0');
    const [toplamAlis2,setToplamAlis2] = useState('0');
    const [toplamSatis1,setToplamSatis1] = useState('0');
    const [toplamSatis2,setToplamSatis2] = useState('0');
    const [tableItems,setTableItems] = useState([]);
    const [successDeclarationId,setSuccessDeclarationId] = useState('');
    const [openForTable,setOpenForTable] = useState(false);
    const [openTableForAdd,setOpenTableForAdd] = useState(true);
    const [openForPreview,setOpenForPreview] = useState(false);
    const [openForDocument,setOpenForDocument] = useState(false);
    const [openForClear,setOpenForClear] = useState(false);
    const [c1,setC1] = useState(null);
    const [selectedRow,setSelectedRow] = useState(-1);
    const [checked, setChecked] = useState(false);
    const charLimitWarning1 = "Bu alana maksimum 300 karakter girişi yapılmalıdır. Daha fazla karakter içeren bir metin girişi veya kopyalama yapıldığında MKK'ya gönderilen pdf dosya sadece 300 karakterlik bölümü içerecek şekilde oluşturulur. Bildirimin eksik bilgi içermemesi açısından bu hususun dikkate alınması gerekmektedir.";
    const charLimitWarning2 = "Bu alana maksimum 1600 karakter girişi yapılmalıdır. Daha fazla karakter içeren bir metin girişi veya kopyalama yapıldığında MKK'ya gönderilen pdf dosya sadece 1600 karakterlik bölümü içerecek şekilde oluşturulur. Bildirimin eksik bilgi içermemesi açısından bu hususun dikkate alınması gerekmektedir.";
    const investorType = localStorage.getItem('InvestorType');

    const [values, setValues] = useState({
        eyatUserId: props.myselfId,
        subject: '',
        company: '',
        correction: correctionList[1].value,
        nameAndSurname: nameOrTitle,
        tuzelName: '',
        gorev: '',
        otherNames: '',
        declaration: '........... tarihinde ........ TL fiyattan toplam ................ adet özel sektör tahvili/banka bonosu/...... alış/satış işlemi tarafımızca gerçekleştirilmiştir. İşlemin detaylarına ilişkin bilgiler aşağıdaki tabloda verilmektedir.',
    })

    const [tableValues, setTableValues] = useState({
        column2: alimSatimList[0].value,
        column3: '',
        column4: '',
        column5: '',
        column6: '',
        column8: '',
        column9: '',
    })

    React.useEffect(()=>{
        values.eyatUserId = props.myselfId;
    },[props.myselfId]);


    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    function handleCheckboxChange() {
        setChecked(!checked);
    }

    const formikRef = (node) => {
        if (node !== null) {
            setValues(node.values);
        }
    }

    const formikRef2 = (node) => {
        if (node !== null) {
            setTableValues(node.values);
        }
    }

    function calculateColumn7() {
        if(tableValues.column5 !== "" && tableValues.column5 !== "0," && tableValues.column6 !== "" && tableValues.column6 !== "0,"){
            var bigdecimal1 = new bigdecimal.BigDecimal(tableValues.column5)
            var bigdecimal2 = new bigdecimal.BigDecimal(tableValues.column6)

            return (bigdecimal1.multiply(bigdecimal2)).toString()
        }
        else
            return ""
    }

    return(
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef}
                    initialValues={values}
                    validate={values => {
                        const errors = {};

                        if(!values.eyatUserId){
                            setNameOrTitle('');
                        }else {
                            if (values.eyatUserId === props.myselfId) {
                                setNameOrTitle(localStorage.getItem("NameSurname"));
                            } else {
                                for (var i = 0; i < props.attorneys.length; i++) {
                                    if (props.attorneys[i].value === values.eyatUserId) {
                                        var strArr = props.attorneys[i].label.split(" adına ");
                                        setNameOrTitle(strArr[0]);
                                        break;
                                    }
                                }
                            }
                        }
                        for(var i=0;i<props.companies.length;i++){
                            if(props.companies[i].value===values.company){
                                setCompanyTitle(props.companies[i].label);
                                break;
                            }
                        }
                        
                        var errorControl = false;
                        if (!values.eyatUserId) {
                            errorControl = true;
                            errors.eyatUserId = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.company) {
                            errorControl = true;
                            errors.company = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.declaration) {
                            errorControl = true;
                            errors.declaration = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.gorev && investorType !== 'R') {
                            errorControl = true;
                            errors.gorev = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.tuzelName && investorType !== 'R') {
                            errorControl = true;
                            errors.tuzelName = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        
                        if (errorControl)
                            alertBox("", "Hatalı / eksik bilgi girişi yaptınız. Lütfen girdiğiniz verileri kontrol ediniz!", Globals.ALERT_ERROR, props.intl);

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        openModal2()
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            <Grid container spacing={5}>    
                                <Grid item xs={12} container direction="column" justify="flex-end" style={{paddingBottom: theme.spacing(1)}}>
                                    <Grid item style={{height:70}}>
                                        <Dropdown   required
                                                    noMargin
                                                    name="eyatUserId"
                                                    id="eyatUserId"
                                                    title='Bildirim Yapan'
                                                    items={props.attorneys}
                                                    onChange={handleChange}
                                                    helperText={errors.eyatUserId}
                                                    error={Boolean(errors.eyatUserId)}
                                                    containerSelectedItem={values.eyatUserId}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container direction="column" justify="flex-end" style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6">Konu</Typography>
                                    <Grid item style={{height:70}}>
                                        <TextField
                                            fullWidth
                                            id="subject"
                                            name="subject"
                                            label="Konu"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.subject}
                                            helperText={touched.subject && errors.subject}
                                            error={Boolean(touched.subject && errors.subject)}
                                            InputLabelProps={{
                                                shrink: values.subject
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <Typography classes={{h6:classes.titleGutter}}  variant="h6">Bildirime Konu Borsa Şirketi</Typography>
                                    <Grid item style={{height:70}}>
                                        <Dropdown   required
                                                    noMargin
                                                    name="company"
                                                    id="company"
                                                    title='Şirket'
                                                    items={props.companies}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.company)}
                                                    helperText={errors.company}
                                                    containerSelectedItem={values.company}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6">Yapılan Açıklama Düzeltme mi?</Typography>
                                    <Grid item style={{height:70}}>
                                        <Dropdown   name="correction"
                                                    required={false}
                                                    noMargin
                                                    id="correction"
                                                    title='Düzeltme'
                                                    items={correctionList}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.correction)}
                                                    helperText={errors.correction}
                                                    containerSelectedItem={values.correction}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider className={classes.divider} />

                                <Grid item xs={12} style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6"> Kişi Bilgileri</Typography>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <TextField
                                        fullWidth
                                        id="nameAndSurname"
                                        name="nameAndSurname"
                                        label="Ad Soyad / Ticaret Unvanı *"
                                        variant="outlined"
                                        disabled={true}
                                        value={nameOrTitle}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: nameOrTitle
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <TextField
                                        fullWidth
                                        id="tuzelName"
                                        name="tuzelName"
                                        disabled={investorType === "R"}
                                        label="Tüzel Kişi Adına Bildirimi Yapanın Adı Soyadı"
                                        variant="outlined"
                                        value={values.tuzelName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.tuzelName && errors.tuzelName}
                                        error={Boolean(touched.tuzelName && errors.tuzelName)}
                                        InputLabelProps={{
                                            shrink: values.tuzelName
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <TextField
                                        fullWidth
                                        id="gorev"
                                        name="gorev"
                                        label="Görevi"
                                        variant="outlined"
                                        value={values.gorev}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.gorev && errors.gorev}
                                        error={Boolean(touched.gorev && errors.gorev)}
                                        InputLabelProps={{
                                            shrink: values.gorev
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <TextField
                                        fullWidth
                                        id="otherNames"
                                        name="otherNames"
                                        label="Varsa Birlikte Hareket Eden Diğer Gerçek/Tüzel Kişiler"
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        rowsMax={10}
                                        value={values.otherNames}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.otherNames && errors.otherNames}
                                        error={Boolean(touched.otherNames && errors.otherNames)}
                                        inputProps={{
                                            maxLength: 300
                                        }}
                                        InputProps={{
                                        endAdornment: <Typography variant="caption" color={values.otherNames.length === 300 ? "error" : "primary"} style={{alignSelf:"flex-end"}}>{300-values.otherNames.length}/{300}</Typography>
                                        }}
                                        InputLabelProps={{
                                            shrink: values.otherNames
                                        }}
                                    />
                                    <Typography variant="caption" className={classes.typography}>{charLimitWarning1}</Typography>
                                </Grid>

                                <Divider className={classes.divider} />
                                
                                <Grid item xs={12} style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                                    <TextField
                                        fullWidth
                                        id="declaration"
                                        name="declaration"
                                        label="Bildirim Metni *"
                                        variant="outlined"
                                        multiline
                                        rows={12}
                                        rowsMax={18}
                                        value={values.declaration}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.declaration && errors.declaration}
                                        error={Boolean(touched.declaration && errors.declaration)}
                                        inputProps={{
                                            maxLength: 1600
                                        }}
                                        InputProps={{
                                        endAdornment: <Typography variant="caption" color={values.declaration.length === 1600 ? "error" : "primary"} style={{alignSelf:"flex-end"}}>{1600-values.declaration.length}/{1600}</Typography>
                                        }}
                                        InputLabelProps={{
                                            shrink: values.declaration
                                        }}
                                    />
                                    <Typography variant="caption" className={classes.typography}>{charLimitWarning2}</Typography>
                                </Grid>

                            </Grid>

                            <div className={classes.tableWrapper}>
                                <div className={classes.overflowX}>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            rowCount={tableItems.length}
                                            intl={props.intl}
                                        />
                                        <TableBody>
                                            {tableItems.map((row, i) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={'row' + i}
                                                    >
                                                        <TableCell align="left" key={'col1_' + i} id={'c1_' + i}>{formatDate(row.islemTarihi)}</TableCell>
                                                        <TableCell align="left" key={'col2_' + i} id={'c2_' + i}>{row.islemNiteligi}</TableCell>
                                                        <TableCell align="left" key={'col3_' + i} id={'c3_' + i}>{row.isinCode}</TableCell>
                                                        <TableCell align="right" key={'col4_' + i} id={'c4_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.nominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col5_' + i} id={'c5_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.sermayePiyasasiAdedi} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col6_' + i} id={'c6_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemFiyati} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col7_' + i} id={'c7_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemTutari} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col8_' + i} id={'c8_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemdenOnceNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col9_' + i} id={'c9_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemdenSonraNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col10_' + i} id={'c10_' + i}><a onClick={()=>openModal1(false,i)}><EditIcon size="large" className={classes.glowOnHover} /></a></TableCell>
                                                        <TableCell align="right" key={'col11_' + i} id={'c11_' + i}><a onClick={()=>removeItem(i)}><DeleteIcon size="large" className={classes.glowOnHover} /></a></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {
                                                 tableItems.length === 0 ?
                                                 <TableRow>
                                                     <TableCell colSpan={12}>
                                                         Tabloda kayıt bulunmamaktadır.
                                                     </TableCell>
                                                 </TableRow>
                                                 : null
                                            }   
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <Button variant="contained" onClick={()=>openModal1(true,-1)}>
                                                        <AddIcon style={{marginRight:8 }} />
                                                        Tabloya giriş yapmak için tıklayınız
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>

                            <Divider className={classes.divider} />

                            <Grid container justify="flex-end" spacing={2}>
                                <Grid item className={classes.showButton} >
                                    <Button type='reset' variant="contained" color='primary' size='large' fullWidth onClick={()=>setOpenForClear(true)}>
                                        FORMU TEMİZLE
                                    </Button>
                                </Grid>
                                <Grid item className={classes.showButton}>
                                    <Button variant="contained" color='primary' size='large' fullWidth type='submit'>
                                        GÖNDER
                                    </Button>
                                </Grid>
                            </Grid>                         
                        </form>
                    )}
                </Formik>
            </Paper>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openForTable}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    Pay Dışındaki Sermaye Piyaysası Araçlarına İlişkin Bildirimler
                </DialogTitle>
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef2}
                    initialValues={tableValues}
                    validate={values => {
                        const errors = {};

                        if ( c1 == null){
                            errors.c1 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }

                        if (!values.column2) {
                            errors.column2 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column4) {
                            errors.column4 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column5) {
                            errors.column5 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column6) {
                            errors.column6 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        handleClose1(true,openTableForAdd,selectedRow)
                    }}
                    // onReset={(values) =>{ }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            <DialogContent>
                                <DialogContentText>
                                        <MaterialUIDatePicker label="İşlemin Tarihi" disableFuture containerId="mc1" setContainerSelectedDate={setC1} dateString={c1}/>
                                    { errors.c1 && <div className="text-danger">{errors.c1}</div> }
                                    <Dropdown   required
                                                name="column2"
                                                id="column2"
                                                title='İşlem Niteliği'
                                                items={alimSatimList}
                                                onChange={handleChange}
                                                helperText={errors.column2}
                                                error={Boolean(errors.column2)}
                                                containerSelectedItem={values.column2}
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') e.preventDefault();
                                                }}
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="column3"
                                            name="column3"
                                            label="Sermaye Piyasası Aracının ISIN Kodu"
                                            variant="outlined"
                                            value={values.column3}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column3 && errors.column3}
                                            error={Boolean(touched.column3 && errors.column3)}  
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column4"
                                            name="column4"
                                            label="İşleme Konu Sermaye Piyasası Aracının Nominal Tutarı (TL) *"
                                            variant="outlined"
                                            value={values.column4}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column4 && errors.column4}
                                            error={Boolean(touched.column4 && errors.column4)}
                                            InputLabelProps={{
                                                shrink: values.column4
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            fullwidth
                                            margin="normal"
                                            id="column5"
                                            name="column5"
                                            label="İşleme Konu Sermaye Piyasası Aracı Adedi *"
                                            variant="outlined"
                                            value={values.column5}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column5 && errors.column5}
                                            error={Boolean(touched.column5 && errors.column5)}
                                            InputLabelProps={{
                                                shrink: values.column5
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column6"
                                            name="column6"
                                            label="İşlem Fiyatı (TL) *"
                                            variant="outlined"
                                            value={values.column6}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column6 && errors.column6}
                                            error={Boolean(touched.column6 && errors.column6)}
                                            InputLabelProps={{
                                                shrink: values.column6
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column7"
                                            name="column7"
                                            label="İşlem Tutarı (TL)"
                                            variant="outlined"
                                            disabled
                                            value={calculateColumn7()}
                                            InputLabelProps={{
                                                shrink: values.column5 && values.column6
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column8"
                                            name="column8"
                                            label="İşlem Öncesi Sahip Olunan Nominal Tutar (TL)"
                                            variant="outlined"
                                            value={values.column8}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column8 && errors.column8}
                                            error={Boolean(touched.column8 && errors.column8)}
                                            InputLabelProps={{
                                                shrink: values.column8
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column9"
                                            name="column9"
                                            label="İşlem Sonrası Sahip Olunan Nominal Tutar (TL)	"
                                            variant="outlined"
                                            value={values.column9}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column9 && errors.column9}
                                            error={Boolean(touched.column9 && errors.column9)}
                                            InputLabelProps={{
                                                shrink: values.column9
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ padding: 16 }}>
                                <Button onClick={()=>handleClose1(false,openTableForAdd,-1)} color="primary">
                                    Vazgeç
                                </Button>
                                <Button type='submit' variant="contained" color="primary">
                                    Ekle
                                </Button>
                            </DialogActions>
                        </form>
                        )}
                </Formik>
            </Dialog>


            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={openForPreview}
                onClose={closeForPreview}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    <Grid container justify='space-between' alignItems='center' >
                        <Typography display='inline' variant='h6'>Pay Dışındaki Sermaye Piyasası Araçlarına İlişkin Bildirimler</Typography>
                        <Typography display='inline' variant='body2'>{currentTime}</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            <Typography gutterBottom variant='h6' align='center' style={{color:'#800000'}}>SÜREKLİ BİLGİLERE İLİŞKİN ÖZEL DURUM AÇIKLAMASI</Typography>
                            <p><span className={classes.fontBold}>Konu: </span>{values.subject}</p>
                            <p><span className={classes.fontBold}>Yapılan Açıklama Düzeltme mi?: </span>{values.correction === 'Y' ? 'EVET' : 'HAYIR'}</p>
                            <p><span className={classes.fontBold}>Bildirime Konu Borsa Şirketi: </span>{companyTitle}</p>
                            <br/>
                            <div style={{position:'relative'}}>
                                <div className={classes.thinBox}>
                                    <p>{values.declaration}</p>
                                </div>
                            </div>
                            <br/><br/>
                            <p><span className={classes.fontBold}>Ad Soyad/Ticaret Unvanı:</span> {nameOrTitle}</p>
                            <p><span className={classes.fontBold}>Tüzel Kişi Adına Bildirimi Yapanın Adı Soyadı: </span>{values.tuzelName}</p>
                            <p><span className={classes.fontBold}>Görevi: </span>{values.gorev}</p>
                            <p><span className={classes.fontBold}>Varsa Birlikte Hareket Eden Diğer Gerçek/Tüzel Kişiler: </span>{values.otherNames}</p>
                            {
                                tableItems.length > 0 ?
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    style={{marginBottom: 16}}
                                >
                                    <EnhancedTableHead
                                        rowCount={tableItems.length}
                                        intl={props.intl}
                                    />
                                    <TableBody>
                                        {tableItems.map((row,i)=>{
                                                return(
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={'row' + i}
                                                    >
                                                        <TableCell align="left" key={'column1_' + i} id={'column1_' + i}>{formatDate(row.islemTarihi)}</TableCell>
                                                        <TableCell align="left" key={'column2_' + i} id={'column2_' + i}>{row.islemNiteligi}</TableCell>
                                                        <TableCell align="left" key={'column3_' + i} id={'column3_' + i}>{row.isinCode}</TableCell>
                                                        <TableCell align="right" key={'column4_' + i} id={'column4_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.nominalTutar} displayType="text"/>
                                                        </TableCell>
                                                        <TableCell align="right" key={'column5_' + i} id={'column5_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.sermayePiyasasiAdedi} displayType="text"/>
                                                        </TableCell>
                                                        <TableCell align="right" key={'column6_' + i} id={'column6_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemFiyati} displayType="text"/>
                                                        </TableCell>
                                                        <TableCell align="right" key={'column7_' + i} id={'column7_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemTutari} displayType="text"/>
                                                        </TableCell>
                                                        <TableCell align="right" key={'column8_' + i} id={'column8_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemdenOnceNominalTutar} displayType="text"/>
                                                        </TableCell>
                                                        <TableCell align="right" key={'column9_' + i} id={'column9_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.islemdenSonraNominalTutar} displayType="text"/>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={3} className={classes.fontBold}>TOPLAM ALIŞ:</TableCell>
                                                <TableCell align="right" className={classes.fontBold}>{toplamAlis1}</TableCell>
                                                <TableCell align="right" colSpan={3} className={classes.fontBold}>{toplamAlis2}</TableCell>
                                                <TableCell align="right" colSpan={2} className={classes.fontBold}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} className={classes.fontBold}>TOPLAM SATIŞ:</TableCell>
                                                <TableCell align="right" className={classes.fontBold}>{toplamSatis1}</TableCell>
                                                <TableCell align="right" colSpan={3} className={classes.fontBold}>{toplamSatis2}</TableCell>
                                                <TableCell align="right" colSpan={2} className={classes.fontBold}></TableCell>
                                            </TableRow>

                                    </TableBody>
                                </Table>
                                :
                                null
                            }
                            
                            {
                                values.eyatUserId !== props.myselfId ?
                                <p>
                                    <span className={classes.fontBold}>
                                        Bu bildirim {nameOrTitle} adına vekaleten {localStorage.getItem("NameSurname")} tarafından yapılmıştır.
                                    </span>
                                </p>
                                : null
                            }
                            <FormControlLabel style={{marginBottom:0}}
                                control = {
                                    <Checkbox
                                        checked = {checked}
                                        onChange = {handleCheckboxChange}
                                        value = {checked}
                                    />
                                }
                                label = {props.intl.formatMessage({id: "KAP_DISCLOSURE.CHECKBOX.APPROVE_DISCLOSURE"})}
                            >
                            </FormControlLabel>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding:16}}>
                    <Button onClick={()=>handleClose2(false)} color="primary">
                        Vazgeç
                    </Button>
                    <Button onClick={()=>handleClose2(true)} variant="contained" color="primary" >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>
            
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openForDocument}
                    onClose={closeForDocument}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">
                        İşlem Tamamlandı
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Bildiriminiz önizlemede görüntülendiği şekilde gönderilmiştir. <br/><span className={classes.fontBold}>0212 334 57 00/Dahili 57 17</span> numaralı telefondan teyit almanız gerekmektedir.
                            <br/><br/>
                            <Typography variant='h6'>Bildirim No:
                                <Typography variant='h6' component='span' classes={{h6:classes.fontLight}}>{" "+successDeclarationId}</Typography>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding:16}}>
                        <Button onClick={()=>handleClose3()} variant="contained" color="primary">
                            BİLDİRİMİ İNDİR
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openForClear}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">
                        Formu Temizle
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                                Girdiğiniz tüm bilgiler temizlenecektir. Onaylıyor musunuz?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding:16}}>
                        <Button onClick={()=>setOpenForClear(false)} color="primary">
                            VAZGEÇ
                        </Button>
                        <Button onClick={()=>clearForm()} variant="contained" color="primary">
                            TEMİZLE
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}
export default injectIntl(
    connect(
        null,
    )(PayDisi)
);