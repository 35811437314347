import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {get, post, checkHttpStatus} from "../../../utils/RestUtilsWithAxios";
import {
    GET_GENERIC_PORTFOLIO,
    GET_SECURITY_BASED_PORTFOLIO,
    GET_INTERMEDIARY_BASED_PORTFOLIO,
    GET_NO_DISPOSAL_PORTFOLIO,
    GET_PIE_CHART_DATA,
    GET_SECURITIES_PIE_CHART_DATA,
    GET_INTERMEDIARY_PIE_CHART_DATA,
    GET_DISTRAINT_INFO,
    DOWNLOAD_EXCEL_GENERIC_PORTFOLIO,
    DOWNLOAD_PDF_GENERIC_PORTFOLIO,
    DOWNLOAD_EXCEL_SECURITY_BASED_PORTFOLIO,
    DOWNLOAD_PDF_SECURITY_BASED_PORTFOLIO,
    DOWNLOAD_EXCEL_INTERMEDIARY_BASED_PORTFOLIO,
    DOWNLOAD_PDF_INTERMEDIARY_BASED_PORTFOLIO,
    DOWNLOAD_EXCEL_NO_DISPOSAL_PORTFOLIO,
    DOWNLOAD_PDF_NO_DISPOSAL_PORTFOLIO,
    DOWNLOAD_EXCEL_DISTRAINT_INFO
}                                       from '../../../constants/uri';
import MaterialUIDatePicker from '../../../components/EyatUIDatePicker';
import DownloadButtons from '../../../components/PdfAndExcelDownloadButtons';
import GenericTables from './GenericPortfolio';
import DistraintInfo from './DistraintInfo'
import SecurityBasedTables from './SecurityBasedPortfolio';
import IntermediaryBasedTables from './IntermediaryBasedPortfolio';
import NoDisposalTables from './NoDisposalPortfolio';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import * as DownloadUtils from '../../../utils/downloadUtils';
import * as Globals from '../../../constants/globals';
import PortfolioPieChart from './PortfolioPieChart';
import {printExtremTableItems, printExpandables, printThisPage, formatDecimal, stableSort, getSorting, formatDate}
    from '../../../utils/ExportUtils';
import InfoDialog from './InfoDialog';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    navButton: {
        maxWidth:'100%',
        marginLeft: 2,
        marginRight: 2,
        backgroundColor: Globals.COLOR_TRANSPARENT_RED,
        borderRadius: 4,   
    },
    activeNavButton: {
        maxWidth:'100%',
        marginLeft: 2,
        marginRight: 2,
        backgroundColor: Globals.COLOR_FULL_RED,
        borderRadius: 4,  
    },
    nav: {
        justifyContent: "space-around",
        height: "unset",
        padding: 5,
        borderRadius: 4,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: theme.spacing(2)
    },
    navText: {
        color: "white",
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10
        },
    },
    totalValueBox: {
        backgroundColor: Globals.COLOR_DARK_GRAY,
        padding: 10,
        borderRadius: 4,
        flex:1,
        display:"flex",
        margin: 8
    },
    totalValueText: {
        color: "white"
    },
    selectedValueBox: {
        padding: 10,
        borderRadius: 4,
        flex:1,
        display:"flex",
        margin: 8
    },
    textContainer: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    selectedMarketValue: {
        textAlign: "center",
        fontSize: 16,
        position:"absolute"
    },
}));

/**
 * formatMonthOrDay
 * @param m
 * @return {*}
 */
function formatMonthOrDay(m) {
    if(m<10)
        return '0'+m;
    else
        return m;
}

/**
 * beginInit
 * @return {string}
 */
function beginInit() {
    var date = new Date();
    return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
        (formatMonthOrDay(date.getDate())).toString();
}

/**
 * BalancePortfolio
 * @param props
 * @return {*}
 * @constructor
 */
function BalancePortfolio (props) {
    const imHeadRows = [
        { id: 'stockName', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.STOCK_NAME"}), style:{minWidth:200} },
        { id: 'addDefCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.ACCOUNT_DEF"}) },
        { id: 'subAccCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.SUB_ACCOUNT"}) },
        { id: 'count', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.COUNT"}) },
        { id: 'price', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.PRICE"}) },
        //{ id: 'ratio', numeric: true, disablePadding: false, label: props.intl.
        // formatMessage({id:"PORTFOLIO.TABLE.ALLOCATION"}) },
        { id: 'lastChangeDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.LAST_CHANGE_DATE"}) },
        { id: 'marketValue', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.MARKET_VALUE"}) },
    ];
    const sbHeadRows = [
        { id: 'account', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.ACCOUNT_NAME"}), style:{minWidth:140} },
        { id: 'addDefCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.ACCOUNT_DEF"}) },
        { id: 'subAccCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.SUB_ACCOUNT"}) },
        { id: 'count', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.COUNT"}) },
        { id: 'price', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.PRICE"}) },
        { id: 'ratio', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.ALLOCATION"}) },
        { id: 'lastChangeDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.LAST_CHANGE_DATE"}) },
        { id: 'marketValue', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.MARKET_VALUE"}) },
    ];
    const ndHeadRows = [
        { id: 'intermediary', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.INTERMEDIARY_NAME"}) },
        { id: 'count_3', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.COUNT"}) },
        { id: 'description', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.DESCRIPTION"}) },
    ];
    const gpHeadRows = [
        { id: 'accNo',     numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.MEMBER"}) },
        { id: 'mbrAccNo',   numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ACCOUNT_NAME"}) },
        { id: 'stockClass',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.CLASS"}), style:{maxWidth:80} },
        { id: 'exchangeCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.EXCHANGE_CODE"}) },
        { id: 'stockName',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.STOCK_NAME"}) },
        { id: 'addDefCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ACCOUNT_DEF"}) },
        { id: 'subAccCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.SUB_ACCOUNT"}) },
        { id: 'count',      numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.COUNT"}) },
        { id: 'price',      numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.PRICE"}) },
        { id: 'marketValue', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.MARKET_VALUE"}) },
        { id: 'ratio', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ALLOCATION"}) },
        { id: 'xmarketDesc', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.XMARKET_DESC"}) },
    ];

    const distraintHeadRows = [
        { id: 'executiveOffice', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.EXECUTIVE_OFFICE"}) },
        { id: 'fileNo',   numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.FILE_NO"}) },
        { id: 'noticeDate',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.NOTICE_DATE"}) },
    ];

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [selectedDate, setSelectedDate] = React.useState(beginInit());
    const [checked, setCheckBox] = React.useState(false);
    const [totalMarketValue, setTotalMarketValue] = React.useState(null);
    const [genericData, setGenericData] = React.useState(null);
    const [securityData, setSecurityData] = React.useState(null);
    const [intermediaryData, setIntermediaryData] = React.useState(null);
    const [noDisposalData, setNoDisposalData] = React.useState(null);
    const [distraintInfo, setDistraintInfo] = React.useState(null);
    const [chartData, setChartData] = React.useState(null);
    const [rounded, setRounded] = React.useState(false);

    React.useEffect(() => {
        setChartData(null)
        retrieveData(value);
     }, [selectedDate,checked, value]);

    /**
     * prepareGenericPortfolioItemArr
     * @param trArray
     * @return stableSort(setArray, getSorting("asc", 0))
     */
    function prepareGenericPortfolioItemArr(trArray) {
        var setArray = [];
        for(var i=0;i<trArray.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(trArray[i].accNo);
            valueItem.values.push(trArray[i].mbrAccNo);
            valueItem.values.push(trArray[i].stockClass);
            valueItem.values.push(trArray[i].exchangeCode);
            valueItem.values.push(trArray[i].stockName + " - " + trArray[i].isin);
            valueItem.values.push(trArray[i].addDefCode);
            valueItem.values.push(trArray[i].subAccCode);
            valueItem.values.push(formatDecimal(trArray[i].count.toFixed(3)));
            if(trArray[i].kfs=="K"){
                valueItem.values.push("-")
                valueItem.values.push("-")
            }else{
                valueItem.values.push(formatDecimal(trArray[i].price.toFixed(2)));
                valueItem.values.push(formatDecimal(trArray[i].marketValue.toFixed(2)));
            }
            valueItem.values.push('%'+(formatDecimal((trArray[i].ratio * 100).toFixed(2))));
            valueItem.values.push(trArray[i].xmarketDesc);
            setArray.push(valueItem);
        }
        return stableSort(setArray, getSorting("asc", 0));
    }

        /**
     * prepareGenericPortfolioItemArr
     * @param trArray
     * @return stableSort(setArray, getSorting("asc", 0))
     */
        function prepareDistraintInfoArr(trArray) {
            var setArray = [];
            for(var i=0;i<trArray.length;i++){
                var valueItem = {};
                valueItem.values = [];
                valueItem.values.push(trArray[i].executiveOffice);
                valueItem.values.push(trArray[i].fileNo);
                valueItem.values.push(trArray[i].noticeDate);
                setArray.push(valueItem);
            }
            return stableSort(setArray, getSorting("asc", 0));
        }

    /**
     * printIntermediary
     */
    function printIntermediary() {
        var valAndHeader = prepareValueAndHeaderList();
        printExpandables(valAndHeader.valueList,valAndHeader.headerList,imHeadRows,
            'ÜYE DETAYINDA PORTFÖY BAKİYE RAPORU - ' + formatDate(selectedDate),false);
    }

    /**
     * prepareValueAndHeaderList
     * @return var valueAndHeader
     */
    function prepareValueAndHeaderList() {
        var valueAndHeader = {};
        var newValues = [];
        var flag = false;
        intermediaryData.map((val) => {
            if(newValues.length > 0) {
                newValues.map((newVal, index) => {
                    if(val.account === newVal.id){
                        newValues[index].rows.push(val);
                        flag = true;
                    }
                })
            }
            if(!flag){
                newValues.push({id: val.account, rows: [val]})
            }
            flag = false;
        });
        var valueList = [];
        var headerList = [];
        for(var i=0;i<newValues.length;i++){
            headerList.push(newValues[i].id);
            var addToList = prepareItemArray(newValues[i].rows);
            valueList.push(addToList);
        }
        valueAndHeader.valueList = valueList;
        valueAndHeader.headerList = headerList;
        return valueAndHeader;
    }

    /**
     * prepareItemArray
     * @param trArray
     * @return {Array}
     */
    function prepareItemArray(trArray) {
        var setArray = [];
        for(var i=0;i<trArray.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(trArray[i].stockName);
            valueItem.values.push(trArray[i].addDefCode);
            valueItem.values.push(trArray[i].subAccCode);
            valueItem.values.push(formatDecimal(trArray[i].count.toFixed(3)));
            trArray[i].price==="-"?valueItem.values.push("-"):valueItem.values.push(formatDecimal(trArray[i].price.toFixed(2)));
            //valueItem.values.push('%'+(formatDecimal((trArray[i].ratio * 100).toFixed(2))));
            valueItem.values.push(trArray[i].lastChangeDate);
            trArray[i].marketValue==="-"?valueItem.values.push("-"):valueItem.values.push(formatDecimal(trArray[i].marketValue.toFixed(2)));
            setArray.push(valueItem);
        }
        return setArray;
    }

    /**
     * retrieveData
     * @param tabIndex
     */
    function retrieveData (tabIndex) {
        setGenericData(null)
        setIntermediaryData(null)
        setSecurityData(null)
        setNoDisposalData(null)
        setDistraintInfo(null)        
        switch(tabIndex) {
            case 0:
                post(GET_GENERIC_PORTFOLIO,{'dateField': selectedDate, 'checkbox': checked})
                .then(function (response) {
                    response.data.accountBalanceInfos.filter(item=>item.kfs==="K").map(item=>{
                        item.price="-";
                        item.marketValue="-"
                    })
                    setGenericData(response.data);
                    setTotalMarketValue(response.data.totalMarketValue)
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                post(GET_PIE_CHART_DATA,{'dateField': selectedDate, 'checkbox': checked})
                .then(function (response) {
                    setChartData(response.data);
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                break;
            case 1:
                post(GET_SECURITY_BASED_PORTFOLIO,{'dateField': selectedDate, 'checkbox': checked})
                .then(function (response) {
                    if(response.data.listMap.HS!==undefined){
                        response.data.listMap.HS.filter(item=>item.kfs=="K")
                        .map(item=>{
                            item.price="-";
                            item.marketValue="-"
                        })
                    }
                    setSecurityData(response.data.listMap);
                    setTotalMarketValue(response.data.totalMarketValue)
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                post(GET_SECURITIES_PIE_CHART_DATA,{'dateField': selectedDate, 'checkbox': checked})
                .then(function (response) {
                    response.data.filter(item=>item.kfs=="K")
                    .map(item=>{
                        item.value="-";
                    })
                    setChartData(response.data);
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                break;
            case 2:
                post(GET_INTERMEDIARY_BASED_PORTFOLIO,{'dateField': selectedDate, 'checkbox': checked})
                .then(function (response) {
                    response.data.list.filter(item=>item.kfs=="K").map(item=>{
                        item.price="-";
                        item.marketValue="-"
                    })
                    setIntermediaryData(response.data.list);
                    setTotalMarketValue(response.data.totalMarketValue)
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                post(GET_INTERMEDIARY_PIE_CHART_DATA,{'dateField': selectedDate, 'checkbox': checked})
                .then(function (response) {
                    setChartData(response.data);
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                break;
            case 3:
                get(GET_NO_DISPOSAL_PORTFOLIO)
                .then(function (response) {
                    setNoDisposalData(response.data);
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                break;
            case 4:
                get(GET_DISTRAINT_INFO)
                .then(function (response) {
                    setDistraintInfo(response.data);
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
                break;
        }
        setRounded(checked);
    }

    /**
     * isEmpty
     * @param obj
     * @return {boolean}
     */
    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    /**
     * handleChange
     * @param event
     * @param newValue
     */
    function handleChange(event, newValue) {
        retrieveData(newValue)
        setValue(newValue);
    }

    /**
     * handleCheckBox
     */
    function handleCheckBox() {
        setCheckBox(!checked);
    }

    /**
     * isChartVisible
     * @return {boolean}
     */
    function isChartVisible(){
        return chartData && chartData.length > 0 && value !== 3 && value !== 2 && value !== 4
    }

    /**
     * downloadAsExcel
     * @param serviceName
     * @param fileName
     */
    function downloadAsExcel(serviceName, fileName) {
        var doPost = serviceName != DOWNLOAD_EXCEL_NO_DISPOSAL_PORTFOLIO && serviceName != DOWNLOAD_EXCEL_DISTRAINT_INFO
        
        if (doPost) {
            post(serviceName,{'dateField': selectedDate, 'checkbox': checked})
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, fileName, Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
        } else {
            get(serviceName)
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, fileName, Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
        }
    }

    /**
     * downloadAsPdf
     * @param serviceName
     * @param fileName
     */
    function downloadAsPdf(serviceName, fileName) {
        if (serviceName !== DOWNLOAD_PDF_NO_DISPOSAL_PORTFOLIO) {
            post(serviceName,{'dateField': selectedDate, 'checkbox': checked})
            .then(function (response) {
                DownloadUtils.downloadFileByContent(response.data, fileName, Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
        } else if (serviceName === DOWNLOAD_PDF_NO_DISPOSAL_PORTFOLIO) {
            get(serviceName)
            .then(function (response) {
                DownloadUtils.downloadFileByContent(response.data, fileName, Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
        }
    }

    return (
            <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container direction={ isChartVisible() ? "row-reverse" : "row" } spacing={2}
                              style={{marginBottom:16}}>
                            { isChartVisible() ?
                                <PortfolioPieChart data={chartData} totalMarketValue={totalMarketValue} />
                                :
                                <InfoDialog />
                            }
                            <Grid item xs={12} md={isChartVisible() ? 4 : 6}>
                                <div style={{marginBottom: 24}}>
                                    <MaterialUIDatePicker setContainerSelectedDate={setSelectedDate}
                                                          dateString={selectedDate} disableFuture={true}
                                                          disabled={(value === 3 || value === 4) ? true : false} />
                                    <Typography variant="body2"
                                                style={{display:(value === 3 || value === 4) ? 'none' : 'block'}}>{props.intl.
                                    formatMessage({id: "PORTFOLIO.MESSAGE.PLEASE_ENTER_DATE"})}</Typography>
                                </div>
                                {value !=4 &&
                                <div>
                                    <FormControlLabel style={{marginBottom:0}}
                                        control = {
                                            <Checkbox
                                                checked = {checked}
                                                onChange = {handleCheckBox}
                                                value = {checked}
                                                color = 'primary'
                                            />
                                        }
                                        label = {props.intl.formatMessage({id: "PORTFOLIO.COMMON.CHECKBOX_MESSAGE"})}
                                    >
                                    </FormControlLabel>
                                </div>
                                }
                            </Grid>
                        </Grid>
                        <BottomNavigation
                            value={value}
                            onChange={handleChange}
                            showLabels
                            classes={{root: classes.nav}}
                            >
                            <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"PORTFOLIO.TABS.ALL_SECURITIES"})}</span>}
                                                    classes={{root: value === 0 ?
                                                            classes.activeNavButton :
                                                            classes.navButton}}  />
                            <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"PORTFOLIO.TABS.SECURITY_BASED"})}</span>}
                                                    classes={{root: value === 1 ?
                                                            classes.activeNavButton :
                                                            classes.navButton}} />
                            <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"PORTFOLIO.TABS.INTERMEDIARY_BASED"})}</span>}
                                                    classes={{root: value === 2 ?
                                                            classes.activeNavButton :
                                                            classes.navButton}} />
                            <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"PORTFOLIO.TABS.NO_RIGHT_OF_DISPOSAL"})}</span>}
                                                    classes={{root: value === 3 ?
                                                            classes.activeNavButton :
                                                            classes.navButton}} />
                            <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"PORTFOLIO.TABS.DISTRAINT_INFO"})}</span>}
                                                    classes={{root: value === 4 ?
                                                            classes.activeNavButton :
                                                            classes.navButton}} />
                        </BottomNavigation>
                        {
                            value === 0 ?
                                genericData ?
                                    genericData.accountBalanceInfos.length > 0 ?
                                    [
                                        <GenericTables intl={props.intl} securities={genericData}
                                                       roundedNumbers={rounded} />,
                                        <DownloadButtons
                                            pdfOnClick={() => printThisPage(
                                                prepareGenericPortfolioItemArr(genericData.accountBalanceInfos),
                                                gpHeadRows,'TÜM KIYMETLER PORTFÖY BAKİYE RAPORU - ' + formatDate(selectedDate))}
                                                        excelOnClick={() => downloadAsExcel(
                                                            DOWNLOAD_EXCEL_GENERIC_PORTFOLIO, "Tüm Kıymetler " + selectedDate + ".xls")}
                                        />
                                    ]
                                    :
                                    <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                                        {props.intl.formatMessage({id:"PORTFOLIO.COMMON.NO_DATA_MESSAGE"})}
                                    </Typography>
                                :
                                null
                            :
                            value === 1 ?
                                securityData ?
                                    !isEmpty(securityData) ?
                                    [
                                        <SecurityBasedTables intl={props.intl} securities={securityData}
                                                             roundedNumbers={rounded} />,
                                        <DownloadButtons pdfOnClick={() => printExtremTableItems(Object.
                                            keys(securityData),Object.values(securityData),sbHeadRows,1,
                                            'MENKUL KIYMET DETAYINDA PORTFÖY BAKİYE RAPORU - ' + formatDate(selectedDate))}
                                                        excelOnClick={() => downloadAsExcel(
                                                            DOWNLOAD_EXCEL_SECURITY_BASED_PORTFOLIO,
                                                            "Menkul Kıymet Detayında " + selectedDate + ".xls")}
                                        />
                                    ]
                                    :
                                    <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                                        {props.intl.formatMessage({id:"PORTFOLIO.COMMON.NO_DATA_MESSAGE"})}
                                    </Typography>
                                :
                                null
                            :
                            value === 2 ?
                                intermediaryData ?
                                    intermediaryData.length > 0 ?
                                    <div>
                                        <IntermediaryBasedTables intl={props.intl} securities={intermediaryData}
                                                                 roundedNumbers={rounded} />
                                        <DownloadButtons pdfOnClick={()=>{printIntermediary();}}
                                                        excelOnClick={() => downloadAsExcel(
                                                            DOWNLOAD_EXCEL_INTERMEDIARY_BASED_PORTFOLIO,
                                                            "Üye Detayında " + selectedDate + ".xls")}
                                        />
                                    </div>
                                    :
                                    <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                                        {props.intl.formatMessage({id:"PORTFOLIO.COMMON.NO_DATA_MESSAGE"})}
                                    </Typography>
                                :
                                null
                            :
                            value === 3 ?
                                noDisposalData ?
                                    !isEmpty(noDisposalData) ?
                                    <div>
                                        <NoDisposalTables intl={props.intl} securities={noDisposalData}
                                                          roundedNumbers={rounded} />
                                        <DownloadButtons pdfOnClick={() => printExtremTableItems(Object.
                                            keys(noDisposalData),Object.values(noDisposalData),ndHeadRows,2,
                                            'TASARRUF HAKKI BULUNMAYAN KIYMETLER')}
                                                        excelOnClick={() => downloadAsExcel(
                                                            DOWNLOAD_EXCEL_NO_DISPOSAL_PORTFOLIO,
                                                            "Tasarruf Hakkı Bulunmayan Kıymetler.xls")}
                                        />
                                    </div>
                                    :
                                    <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                                        {props.intl.formatMessage({id:"PORTFOLIO.COMMON.NO_DATA_MESSAGE"})}
                                    </Typography>
                                :
                                null
                            :    
                                distraintInfo ?
                                    !isEmpty(distraintInfo) ?
                                    <div>
                                        <DistraintInfo intl={props.intl} distraintInfo={distraintInfo}
                                                          roundedNumbers={rounded} />
                                        <DownloadButtons pdfOnClick={() => printThisPage(
                                                prepareDistraintInfoArr(distraintInfo),
                                                distraintHeadRows,'HACİZ BİLGİLERİ RAPORU')}
                                                        excelOnClick={() => downloadAsExcel(
                                                            DOWNLOAD_EXCEL_DISTRAINT_INFO,
                                                            "Haciz Bilgileri.xls")}
                                        />
                                    </div>
                                    :
                                    <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                                        {props.intl.formatMessage({id:"PORTFOLIO.COMMON.NO_DISTRAINT_MESSAGE"})}
                                    </Typography>
                                :
                                null
                        }
                    </Paper>
            </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(BalancePortfolio)
);