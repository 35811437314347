import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  GET_REPORT_SUBMISSION
} from "../../../constants/uri";
import { checkHttpStatus, get, post } from "../../../utils/RestUtilsWithAxios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "200px",
    margin: "0"
  },

  tableWrapper: {
    overflowX: "auto",
    backgroundColor: "transparent",
  },
  fontSize13: {
    fontSize: 13,
  },
  showButton: {
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },
  },
  whiteText: {
    color: "white",
  },
}));
 

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */

/**
 * ReportSubmissionSystem
 * @param props
 * @return {*}
 * @constructor
 */
function ReportSubmissionSystem(props) {
  const { intl,reportSubmissionRows, setReportSubmissionRows } = props;
  const classes = useStyles();
  const [update, setUpdate] = React.useState(false);
  

  React.useEffect(() => {
    get(GET_REPORT_SUBMISSION)
      .then(function(response) {
        setReportSubmissionRows(response.data);
        setUpdate(false);
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }, [update]);

  /**
   * handleChange
   * @param event
   * @param notifTypeCode
   */
  function handleChange(event, notifTypeCode) {
    let data = reportSubmissionRows;
    data.forEach(function(row) {
      if (row.notifTypeCode === notifTypeCode) {
        const target = event.target;
        const value =
          target.type === "checkbox" ? target.checked : target.value;
        row.viaEmail = value;
        document.getElementById("viaEmail_" + notifTypeCode).value = value;
      }
    });
    setReportSubmissionRows(data);
  }
 

  return (
    <div className={classes.root}>
      
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <TableBody>
          {reportSubmissionRows.map((row, index) => {
            return ( 
              <TableRow key={row.name + index}   >
                
               <TableCell
                  align="left"
                  style={{
                    border: "none",
                    paddingLeft: 0,
                    paddingRight: 0,
                    padding: 0,
                  }}
                  className={classes.fontSize13}
                >
                  <Checkbox
                    id={"viaEmail_" + row.notifTypeCode}
                    onChange={(event) => handleChange(event, row.notifTypeCode)}
                    defaultChecked={row.viaEmail}
                    inputProps={{ "aria-labelledby": row.notifTypeCode }}
                  />
                </TableCell>

                <TableCell
                  align="left"
                  style={{
                    border: "none",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  className={classes.fontSize13}
                  key={"processType_" + row.notifTypeCode}
                  id={"messageType_" + row.notifTypeCode}
                >
                  {row.operationType}
                </TableCell>
         
               
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

/**
 * injectIntl
 */
export default injectIntl(ReportSubmissionSystem);
