import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if (orderBy !== 'openDate') {
        return b[orderBy].toString().localeCompare(a[orderBy].toString());
    }
    else {
        if (a[orderBy] == null && b[orderBy] != null)
            return 1;
        else if (a[orderBy] != null && b[orderBy] == null)
            return -1;
        else if (a[orderBy] == null && b[orderBy] == null)
            return 0;
        else {
            const dateIndices = [6, 7, 8, 9, 3, 4, 0, 1];
            var r = 0;
            for (var i = 0; i < dateIndices.length; i++) {
                r = a[orderBy].charCodeAt(dateIndices[i]) - b[orderBy].charCodeAt(dateIndices[i]);
                if (r !== 0)
                    return r;
            }
            return r;
        }
    }
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 13,
        fontWeight: 600
    },
    fontBold: {
        fontWeight: 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'memberTooltip', numeric: false, disablePadding: false, label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.MEMBER" }) },
        { id: 'accountNo', numeric: false, disablePadding: false, label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.ACCOUNT_NO" }) },
        { id: 'openDate', numeric: false, disablePadding: false, label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.OPEN_DATE" }) },
        { id: 'partnerType', numeric: false, disablePadding: false, label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.PARTNERSHIP_TYPE" }) },
        { id: 'accountState', numeric: false, disablePadding: false, label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.ACCOUNT_STATE" }) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {
                                row.secondLabel ?
                                    <div>
                                        {row.label}<br />
                                        <Typography classes={{ subtitle1: headClasses.fontBold }} variant="subtitle1">{row.secondLabel}</Typography>
                                    </div>
                                    :
                                    <div>{row.label}</div>
                            }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13: {
        fontSize: 13
    },
    link: {
        color:'#aa3323'
    }
}));
/**
 * BalanceInfoTable
 * @param props
 * @return {*}
 * @constructor
 */
export default function BalanceInfoTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('memberTooltip');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.accInfoData;

    const heads = [
        { label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.MEMBER" }), key: 'memberTooltip' },
        { label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.ACCOUNT_NO" }), key: 'accountNo' },
        { label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.OPEN_DATE" }), key: 'openDate' },
        { label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.PARTNERSHIP_TYPE" }), key: 'partnerType' },
        { label: props.intl.formatMessage({ id: "ACCOUNT_INFO.TABLE.ACCOUNT_STATE" }), key: 'accountState' },
    ];

    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
        <div>
            <div className={classes.tableWrapper} id="accountTable">
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        intl={props.intl}
                    />
                    <TableBody>
                        {stableSort(rows, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={'row' + index}
                                    >
                                        <TableCell align="left" key={"memberTooltip_" + index} id={"memberTooltip_" + index} className={classes.fontSize13}>{row.memberTooltip}</TableCell>
                                        <TableCell align="left" key={"accountNo_" + index} id={"accountNo_" + index} className={classes.fontSize13}>{row.accountNo}</TableCell>
                                        <TableCell align="left" key={"openDate_" + index} id={"openDate_" + index} className={classes.fontSize13}>{row.openDate}</TableCell>
                                        <TableCell align="left" key={"partnerType_" + index} id={"partnerType_" + index} className={classes.fontSize13}>
                                            <a href="#" onClick={() => { props.selectAccount(row) }}  className={classes.link}>{row.partnerType}</a>
                                        </TableCell>
                                        <TableCell align="left" key={"accountState_" + index} id={"accountState_" + index} className={classes.fontSize13}>{row.accountState}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonText={props.intl.formatMessage({ id: "TABLE.PAGINATION.PREVIOUS" })}
                nextIconButtonText={props.intl.formatMessage({ id: "TABLE.PAGINATION.NEXT" })}
                labelRowsPerPage={props.intl.formatMessage({ id: "TABLE.PAGINATION.PER_PAGE" })}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}